import './confirmation.scss';

import React, { useEffect, useState } from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import { Row, Col, Alert, Table, Spinner } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Backdrop, Button, Card, CardContent, CircularProgress, Grid, Paper, styled, Typography } from '@mui/material';
import { getConfirmation, postConfirmation } from './confirmation.reducer';
import { ScaleLoader } from 'react-spinners';
import PinField from 'react-pin-field';
import { LoadingButton } from '@mui/lab';
import { IConfirmation, IConfirmationPostBody } from 'app/shared/model/confirmation.model';
import { toast } from 'react-toastify';

export const Confirmation = () => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const [code, setCode] = useState<string>("")
  const [otpKey, setOtpKey] = useState<string>("")

  // const account = useAppSelector(state => state.authentication.account);
  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    if(params.get('code')) {
      setCode(params.get('code'));
      dispatch(getConfirmation(params.get('code')));
    }
  }, [pageLocation.search]);

  const loading = useAppSelector(state => state.confirmation.loading);
  const confirmData: IConfirmation = useAppSelector(state => state.confirmation.confirmation);
  const successMessage = useAppSelector(state => state.confirmation.successMessage);

  useEffect(() => {
    if (successMessage) {
      toast.success(translate(successMessage));
    }
  }, [successMessage]);

  const handleOtp = (otp: React.SetStateAction<string>) => {
    setOtpKey(otp);
  };

  const confirmOtpKey = () => {
    const postData: IConfirmationPostBody = {
      code,
      status: 'approve',
      token: otpKey
    }
    dispatch(postConfirmation(postData));
  };

  return (
    <>
      <div style={{
        backgroundColor:'#F2F3F5',
        width: '100vw',
        height: '100vh'
      }} className="d-flex align-items-center justify-content-center flex-column">
        <Grid container spacing={2} sx={{
            textAlign: 'center',
            maxWidth: '500px'
          }}>
          <Grid item xs={12} sx={{
            textAlign: 'center'
          }}>
            <img src="https://navi.miraeasset.co.id/img/logo.png" width="113"></img>
          </Grid>
          <Grid item xs={12} sx={{
            textAlign: 'center',
            maxWidth: '467px'
          }}>
            <Card>
              <CardContent sx={{
                padding: '40px'
              }}>
                <Row className='pb-1'>
                  <Col>
                    <Typography sx={{
                      fontSize: '24px',
                      fontWeight: '700',
                      lineHeight: '32px',
                      textAlign: 'center',
                      color: '#19191A'
                    }}>
                      Please Confirm Your Transaction
                    </Typography>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Typography sx={{
                      fontSize: '14px',
                      fontWeight: '400',
                      lineHeight: '20px',
                      textAlign: 'center',
                      color: '#636566'
                    }}>
                      Type the verification code you see in the picture below to confirm your transaction
                    </Typography>
                  </Col>
                </Row>
                <Row className='py-4'>
                  <Col>
                    <div className={"row d-flex justify-content-center"}>
                      <div className="box-token">
                        {loading ? <Spinner animation="border" role="status" color="danger" /> : 
                        <Typography sx={{
                          fontSize: '24px',
                          fontWeight: '700',
                          lineHeight: '32px',
                          textAlign: 'center',
                          color: '#313233'
                        }}>
                          {confirmData.token !== null ? confirmData.token : 'Error Get Token'}
                        </Typography>}
                        
                      </div>
                      {/* {!loading?<h2 className="boxx" style={{margin: 0}}>{'otpKey'}</h2> :
                      <h2 className="boxx" style={{margin: 0}}><Spinner color='#fff' size="sm" style={{marginBottom: "2px" }}/></h2>} */}
                    </div>
                  </Col>
                </Row>
                <Row><Col>Enter verification code</Col></Row>
                <Row className='pb-4'>
                  <Col>
                    <PinField
                      length={6}
                      onChange={handleOtp}
                      className="mx-1 bg-transparent form-control"
                      validate="0123456789" inputMode="numeric"
                      maxLength={1}
                      style={{
                        width: 56,
                        height: 56,
                        display: 'inline-block',
                        fontSize: 26,
                        textAlign: 'center',
                        padding:0
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <LoadingButton
                      disabled={otpKey.length < 6}
                      loading={loading}
                      fullWidth
                      size="large"
                      variant="contained"
                      color="warning"
                      onClick={confirmOtpKey}
                    >
                      Confirm
                    </LoadingButton>
                  </Col>
                </Row>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Confirmation;
